/* Styles globaux */
html, body {
  margin: 0;
  padding: 0;
  height: 100%;
  box-sizing: border-box;
}

body {
  background: hsl(221deg 16% 12%);
  font-family: "Mona Sans", sans-serif;
  display: grid;
  place-items: center;
}

.container { 
  display: flex; 
  flex-direction: column; 
  gap: 0.8em;
  align-items: start;
}

label {
  cursor: pointer;
  display: flex;
  align-items: center;
  gap: 8px;
  color: rgba(255, 255, 255, 0.6);
  font-size: 1.6em;
  transition: color 0.2s ease;
}

label:hover {
  color: white;
}

input[type="checkbox"] {
  appearance: none;
  width: 36px;
  height: 36px;
  border: 2px solid black;
  border-radius: 24%;
  background: hsl(216, 17%, 15%);
  cursor: pointer;
  transition: background 0.3s ease;
}

input[type="checkbox"]:checked {
  background: hsl(265, 80%, 61%);
  border-color: hsl(265, 56%, 49%);
}

