.country-sections {
  max-width: 1200px;
  margin: 0 auto;
  padding: 20px;
  font-family: Arial, sans-serif;
  line-height: 1.6;
}

.country-section {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 40px;
  padding: 20px;
  background: #fff;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.image-right .country-image {
  order: 2;
}

.image-left .country-image {
  order: 1;
}

.country-image {
  width: 300px;
  height: 200px;
  object-fit: cover;
  border-radius: 8px;
  margin-left: 20px;
}

.country-content {
  flex: 1;
}

.country-content h2 {
  font-size: 1.5rem;
  margin-bottom: 15px;
  color: #333;
}

.country-content p {
  font-size: 1rem;
  margin: 10px 0;
  color: #555;
}

