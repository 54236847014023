html, body {
  margin: 0;
  padding: 0;
  height: 100%;
  width: 100%;
  scroll-behavior: smooth; /* Ajoute le défilement fluide */
}

#root {
  height: 100%;
  width: 100%;
}

