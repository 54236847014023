html, body {
  margin: 0;
  padding: 0;
  height: 100%;
  width: 100%;
  box-sizing: border-box;
}

#root {
  height: 100%;
  width: 100%;
}

.home {
  min-height: 100vh; /* Prend toute la hauteur de l'écran */
  width: 100%;
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-template-rows: 1fr;
  background-color: #151515; /* Ajout pour que ce soit visible */
}

