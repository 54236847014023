.bucketlist-page {
  position: relative;
  min-height: 100vh; /* Minimum hauteur pour prendre toute la page */
  background-color: #151515; /* Fond noir qui suit le défilement */
  display: flex;
  flex-direction: column;
}

.back-button {
  position: absolute;
  top: 20px;
  left: 20px;
  background: none;
  border: none;
  color: white;
  font-size: 1.2em;
  cursor: pointer;
  transition: color 0.3s ease;
}

.back-button:hover {
  color: #f27b9b;
}

.content {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start; /* Aligne le contenu au début */
  padding: 20px;
}

.title {
  color: white;
  font-family: "Bungee", cursive;
  font-size: 2.5em;
  margin-bottom: 20px;
  text-align: center;
}

.container {
  display: flex;
  flex-direction: column;
  align-items: flex-start; /* Aligne les items à gauche */
  gap: 15px;
  background-color: #1c1c1c;
  padding: 20px;
  border-radius: 8px;
  width: 100%;
  max-width: 600px; /* Largeur maximale pour centrer la liste */
}

label {
  display: flex;
  align-items: center;
  color: white;
  font-size: 1.2em;
  gap: 10px;
  cursor: pointer;
  transition: color 0.3s ease;
}

label:hover {
  color: #f27b9b;
}

input[type="checkbox"] {
  width: 20px;
  height: 20px;
  margin-right: 10px;
  cursor: pointer;
}

