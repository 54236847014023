/* Importation des polices pour un style moderne */
@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@400;500;700&display=swap');

/* Configuration générale */
body {
  margin: 0;
  font-family: 'Roboto', sans-serif;
  background-color: #f4f4f4; /* Fond gris clair */
}

/* Conteneur principal */
.blog-list {
  padding: 20px;
  max-width: 1200px;
  margin: 0 auto;
}

.blog-list h1 {
  text-align: center;
  color: #333;
  font-weight: 700;
  font-size: 2rem;
  margin-bottom: 20px;
}

/* Conteneur des cartes */
.blog-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 20px;
  padding: 10px;
}

/* Style des cartes */
.blog-card {
  background: #fff;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  width: 300px;
  overflow: hidden;
  transition: transform 0.2s ease-in-out, box-shadow 0.2s ease-in-out;
}

.blog-card:hover {
  transform: translateY(-5px); /* Effet de survol */
  box-shadow: 0 6px 12px rgba(0, 0, 0, 0.2);
}

/* Image de la carte */
.blog-card img {
  width: 100%;
  height: 180px;
  object-fit: cover; /* Garde une bonne proportion de l'image */
}

/* Contenu de la carte */
.blog-card h3 {
  font-size: 1.25rem;
  margin: 15px;
  color: #333;
  font-weight: 500;
}

.blog-card .date {
  font-size: 0.9rem;
  color: #777;
  margin: 0 15px;
}

.blog-card p {
  margin: 10px 15px;
  font-size: 1rem;
  color: #555;
  line-height: 1.6;
}

/* Auteur */
.blog-card .author {
  font-size: 0.9rem;
  font-style: italic;
  color: #555;
  margin: 15px;
}

/* Bouton ou lien (si nécessaire) */
.blog-card a {
  display: block;
  text-align: center;
  margin: 15px;
  padding: 10px;
  color: #fff;
  background-color: #007bff;
  text-decoration: none;
  border-radius: 5px;
  font-weight: 500;
  transition: background-color 0.2s ease-in-out;
}

.blog-card a:hover {
  background-color: #0056b3;
}

